import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Icon, { IconName } from "../Icon/Icon";

export interface SideBarItemProps {
  icon?: IconName;
  routeTo: string;
  title: string | JSX.Element;
  onClick?(): void;
  disabled?: boolean;
  tooltipText?: string;
  fillIcon?: string;
  isSelected?: boolean;
  classNameOnboarding?: string;
  customClassName?: string;
  textCustomClassName?: string;
  customIcon?: JSX.Element;
}

const SidebarItem = ({
  icon,
  routeTo,
  title,
  disabled,
  isSelected = false,
  classNameOnboarding,
  customIcon = null,
  ...props
}: SideBarItemProps) => {
  return (
    <Link to={routeTo}>
      <button
        className={`${classNameOnboarding} flex w-full
            h800:py-1 h800:my-2 h930:my-4 h930:py-2 py-1 my-2  
          flex-col items-center  ${
            isSelected ? "bg-[#414587]" : "bg-blue-500 "
          } hover:bg-blue-300 rounded-md cursor-pointer`}
        {...props}
      >
        {!icon && customIcon ? (
          customIcon
        ) : (
          <Icon
            icon={icon as IconName}
            className="w-4 h-4 h800:w-5 h800:h-5  h930:h-6  h930:w-6 stroke-white "
          />
        )}
        <p className="text-white text-xs h930:text-sm h800:text-xs">{title}</p>
      </button>
    </Link>
  );
};

const SidebarItemSmall = ({
  icon,
  routeTo,
  title,
  disabled,
  fillIcon = "none",
  isSelected = false,
  classNameOnboarding,
  customIcon = null,
  ...props
}: SideBarItemProps) => {
  let button = (
    <button
      className={`flex w-full my-1 px-2 cursor-pointer py-2 items-center md:py-px md:my-px ${
        isSelected ? "bg-[#414587]" : "bg-blue-500"
      } hover:bg-blue-300 rounded-md xxl:my-0`}
      {...props}
    >
      <div className="mr-2">
        {!icon && customIcon ? (
          customIcon
        ) : (
          <Icon icon={icon as IconName} fill={fillIcon} className="w-4 h-4 stroke-white" />
        )}
      </div>
      <p className="text-white text-xs lg:text-xs 2xl:text-sm">{title}</p>
    </button>
  );
  if (!disabled && routeTo !== "") {
    button = <Link to={routeTo}>{button}</Link>;
  }

  return button;
};

const SidebarItemProject = ({
  icon,
  routeTo,
  title,
  disabled,
  tooltipText,
  customClassName = "",
  textCustomClassName = "",
  customIcon = undefined,
  ...props
}: SideBarItemProps) => {
  console.log("customIcon : ", customIcon);
  let button = (
    <button
      className={`${
        disabled ? "disabled:opacity-50 cursor-not-allowed" : "hover:bg-blue-300 cursor-pointer "
      } flex flex-col w-full my-1 px-2 min-h-24 py-2 items-center bg-blue-500 rounded-md ${customClassName}`}
      {...props}
      disabled={disabled}
    >
      {customIcon ? (
        customIcon
      ) : (
        <Icon icon={icon as IconName} className="w-6 h-6 stroke-green-600 m-auto" />
      )}
      <span className={`text-white ${textCustomClassName}`}>{title}</span>
    </button>
  );
  if (tooltipText && tooltipText !== "") {
    button = <Tooltip title={tooltipText}>{button}</Tooltip>;
  }

  if (!disabled && routeTo !== "") {
    button = <Link to={routeTo}>{button}</Link>;
  }

  return button;
};

export { SidebarItem, SidebarItemSmall, SidebarItemProject };
