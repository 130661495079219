/* eslint-disable indent */
import { IMedia } from "../../../model/unityObject";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../common/state/store";
import { openMediaPreviewModal } from "../../../common/state/slice/modal/modalSlice";
import { ModalPreview } from "./ModalPreview";
import { useTranslation } from "react-i18next";
import MadeWithAiIcon from "src/common/components/MadeWithAiIcon/MadeWithAiIcon";
import hasMediaBeenGeneratedByAi from "src/common/util/HasMediaBeenGeneratedByAi";

export const MEDIA_VIEWER_PLAY_BUTTON = "media-viewer-play-button";
export const MODAL_3D_VIEWER = "modal3dViewer";
export const PLAYER_360_VIEWER = "player360viewer";

export const MediaViewer = ({ media }: { media: IMedia }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const hasBeenGeneratedByAi = hasMediaBeenGeneratedByAi(media);

  let thumbnailToDisplay = <div></div>;
  // Handle video thumbnails
  const customStyle =
    media.type === "video" && media?.thumbnail_s3_url
      ? {
          backgroundImage: `url(${media.thumbnail_s3_url})`,
        }
      : {};

  // Handle image thumbnails
  if (media.type === "image") {
    thumbnailToDisplay = (
      <img
        className="object-cover h-32 sm:h-48"
        src={
          media?.thumbnail_s3_url && media?.thumbnail_s3_url.length > 0
            ? media.thumbnail_s3_url
            : media.s3_url
        }
        alt="image-media"
      />
    );
  }

  // Handle other thumbnails
  const backgroundPicture = () => {
    if (media.type === "audio") {
      return "background-audio";
    } else if (media.type === "model3d") {
      return "background-3d-model";
    } else if (media.type === "pdf") {
      return "background-pdf";
    } else if (media.type === "video") {
      if (media?.thumbnail_s3_url) {
        return "bg-black bg-blend-soft-light bg-opacity-60";
      } else {
        return "background-360-model";
      }
    } else {
      return "";
    }
  };

  return (
    <div className="p-2 flex items-center justify-center w-full h-32 sm:h-48">
      <div
        style={customStyle}
        className={`${backgroundPicture()} relative bg-gray-100 w-full h-full flex items-center justify-center rounded-xl overflow-hidden`}
      >
        {thumbnailToDisplay}
        {hasBeenGeneratedByAi && (
          <div
            style={{
              position: "absolute",
              bottom: "0.9rem",
              right: "0.9rem",
            }}
          >
            <MadeWithAiIcon width={30} height={30} />
          </div>
        )}
      </div>
      <button
        type="button"
        className="absolute btn-primary-fill bg-white text-green-400 z-10 mr-0 rounded-full shadow-md shadow-gray-400 hover:shadow-inner hover:text-white"
        onClick={(e) => {
          e.stopPropagation();
          if (media.type !== "pdf") {
            dispatch(openMediaPreviewModal(`preview_${media.id}`));
          }
        }}
      >
        {media.type === "pdf" ? (
          <a href={media.s3_url} target="_blank" rel="noreferrer">
            {t("general.preview")}
          </a>
        ) : (
          t("general.preview")
        )}
      </button>
      <ModalPreview
        media={media}
        onCloseCb={() =>
          console.log("ModalPreview onClose callback; I could be doing something :3")
        }
      />
    </div>
  );
};
