/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import classNames from "classnames";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { projectContents } from "../state/graphLegacy";
import { isNodeEditable, validateNode } from "../util/graphValidator";
import { Node, NodeAsset, NodeLink, RootLayoutRect } from "../util/nodeTypes";
import useElementRect from "../../../common/util/useElementRect";
import { useNameFormatter } from "../util/NameFormatter";

import Line from "./svg/Line";
import SVGNodeAsset, { AssetType } from "./svg/SVGNodeAsset";
import SVGNodeScene from "./svg/SVGNodeScene";
import { setIsValid } from "../state/graphuSlice";
import "./SVGGraph.scss";
import { useNavigate } from "react-router-dom";
import Icon from "../../../common/components/Icon/Icon";
import { MdInfo } from "react-icons/md";
import { setShouldRerender } from "../state/mediasSliceLegacy";
import { Tooltip } from "src/common/components/Tooltip/Tooltip";
// import { Tooltip as MuiTooltip } from "@mui/material";

const humanizeNodeName = (string: string) => {
  const prefixStart = string.indexOf("_");
  if (prefixStart > -1) {
    string = string.slice(prefixStart + 1);
  }

  // 2) then we split words and keep at most 2 of them
  let elements = string.split(/\s+/g).slice(0, 2);
  elements = elements.flatMap((element) => element.split(/(?=[A-Z]+)/g)).slice(0, 2);

  // 3) optionally we detect "sequenced" strings
  if (elements.length === 1 && /-\d+$/.test(elements[0])) {
    const [firstElement] = elements;
    const suffixStart = firstElement.lastIndexOf("-");
    elements = [firstElement.slice(0, suffixStart), `(${firstElement.slice(suffixStart + 1)})`];
  }

  return elements.join(" ");
};

type Props = {
  // nodes to display
  nodes: Node[];
  layout: RootLayoutRect;
  links: NodeLink[];
  // react to selection
  selectedContentId: number | null;
  onSelect?: (node: Node) => void;
  // nodes that arent linked by action
  hiddenSceneNodes?: Node[];
};

const SVGGraph: React.FC<Props> = ({
  nodes,
  layout,
  links,
  selectedContentId,
  hiddenSceneNodes,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const relevantContents = useSelector(projectContents).entities;
  const contentsArr = Object.entries(relevantContents).map((e) => {
    return { ...e[1] };
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const sizeRect = useElementRect(containerRef);
  const { getHumanizedNodeNameFromNode } = useNameFormatter();
  const { t } = useTranslation();

  // track if the graph is completely valid
  let allNodesAreValid = true;

  // We build and array of all the nodes in the graph.
  // the "graphBuilder" did the hard work of computing the positions, so now we just have to select the right React
  // component per node type and set it's x/y values.

  // Also, let's store the bottom-most element while we're at it. It will be useful later, to position the "addScene" <rect>
  let bottomMostNode = { y: 0 };

  const nodeElements = useMemo(
    () =>
      nodes.map((node, index) => {
        if (bottomMostNode.y < node.y) bottomMostNode = node;

        const contentId = node?.details?.content;
        const relevantC = contentsArr.find((c: any) => {
          return Number(c.id) === Number(contentId);
        });

        const uniqueKey = `${node.modelName}-${node.contentId}`;
        const valid = validateNode(node);
        allNodesAreValid = allNodesAreValid && valid;
        const editable = isNodeEditable(node);
        const { x, y } = node;

        if (index === nodes.length - 1) {
          // at last element we check & dispatch if "allNodesAreValid"
          dispatch(setIsValid(allNodesAreValid));
        }

        switch (node.type) {
          case "scene":
            // eslint-disable-next-line no-case-declarations
            const title = getHumanizedNodeNameFromNode(node);
            return (
              <SVGNodeScene
                title={title}
                valid={valid}
                key={uniqueKey}
                x={x}
                y={y}
                onClick={() => {
                  navigate(`node/${node.modelName}/${node.details.id}`);
                }}
                content={relevantC}
                ctName={node?.details?.name}
                sceneType={node?.details?.scene_type}
                cursor="pointer"
                startScene={node?.details?.is_start_scene}
                className="tour-scene-node"
              />
            );
          case "asset":
            // eslint-disable-next-line no-case-declarations
            const type = detectTypeForAssetNode(node);
            return (
              <SVGNodeAsset
                type={type}
                valid={valid}
                key={uniqueKey}
                disabled={!editable}
                selected={selectedContentId === node.contentId}
                x={x}
                y={y}
                modelName={node.modelName}
                onClick={() => {
                  if (editable) {
                    navigate(`node/${node.modelName}/${node.details.id}`);
                  }
                }}
                content={relevantC}
                ctName={node?.details?.name}
                cursor="pointer"
              />
            );
          default:
            return null;
        }
      }),
    [nodes],
  );

  const width = Math.max((sizeRect?.width || 0) - 100, 0);
  const height = sizeRect?.height || 0;
  const tx = (width - layout.width) / 2;
  const ty = (height - layout.height) / 2;
  const viewBox = `0 0 ${layout.width} ${layout.height}`;

  const unlinkedSceneNodes = hiddenSceneNodes?.map((hsn, i) => {
    const contentId = hsn?.contentId;
    const relevantC = contentsArr.find((c: any) => {
      return Number(c.id) === Number(contentId);
    });
    const { x } = hsn;
    const title = hsn.details.name;
    const humanizedTitle = humanizeNodeName(title);
    return (
      <SVGNodeScene
        title={humanizedTitle}
        valid={false}
        key={i}
        x={x + 150 * i}
        content={relevantC}
        ctName={hsn?.details?.name}
        sceneType="video"
        cursor="pointer"
        details={hsn}
        unlinked
        onClick={() => {
          navigate(`node/${hsn.modelName}/${hsn.details.id}`);
        }}
      />
    );
  });

  const UnlinkedScenesWrapper = () => {
    const numberOfUnlinkedScenes = unlinkedSceneNodes?.length;
    return (
      <svg
        style={{
          visibility: Number(numberOfUnlinkedScenes) <= 0 ? "hidden" : "visible",
        }}
        viewBox={`0 0 ${Number(numberOfUnlinkedScenes) * 150} 150`}
        height={"100%"}
      >
        <g>{unlinkedSceneNodes}</g>
      </svg>
    );
  };

  /* React.useEffect(() => {
    if (!allNodesAreValid) {
      //@todo create an alert slide
      alert({ isOpen: true, msg: "mediaMissing", type: "warning" });
      //dispatch(setAlert({ isOpen: true, msg: "mediaMissing", type: "warning" }));
    }
  }, [projectContents]);*/
  // Draw the lines between nodes, and assigning them an unique (but stable) key
  const lineElements = links.map(({ from, to, linksToScene, index, isNotThatSimple = false }) => {
    const uniqueKey = `${from.modelName}-${from.contentId}-to-${to.modelName}-${to.contentId}`;
    return (
      <Line
        key={uniqueKey}
        from={from}
        to={to}
        linksToScene={linksToScene}
        index={index}
        isNotThatSimple={isNotThatSimple}
      />
    );
  });

  // Below we render the SVG component.
  // this is a bit convoluted since our logic is either:
  // ➡︎ to display the graph centered if the canvas is larger than the graph
  // ➡︎ otherwise if the graph is too big we want to resize the graph (we set height/width but keep viewBox)

  const minScale = 1;
  const maxScale = 3;

  const [graphScale, setGraphScale] = useState(1);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [graphTranslateX, setGraphTranslateX] = useState(0);
  const [graphTranslateY, setGraphTranslateY] = useState(0);

  const svgElement =
    tx >= 0 && ty >= 0 ? (
      // SVG is smaller than the available screen space: draw it centered
      <div
        style={{
          transform: `scale(${graphScale}) translate(${graphTranslateX}px, ${graphTranslateY}px)`,
        }}
      >
        <svg
          key="svg-container"
          id="graph-svg"
          className="tour-graph .graph-svg"
          style={{
            backgroundColor: "inherit",
          }}
          height={height}
          width={width}
          alignmentBaseline="middle"
          textAnchor="middle"
        >
          <g transform={`translate(${tx} ${ty})`}>
            {lineElements}
            {nodeElements}
          </g>
        </svg>
      </div>
    ) : (
      // SVG does not fit: scale it
      // (NOTE: SVG rendering will keep it centered)
      <div
        style={{
          transform: `scale(${graphScale}) translate(${graphTranslateX}px, ${graphTranslateY}px)`,
        }}
      >
        <svg
          key="svg-container"
          id="graph-svg"
          className="tour-graph .graph-svg"
          style={{
            backgroundColor: "inherit",
          }}
          height={height} //"100%"
          width={width} // this slightly improves graph scaling
          viewBox={viewBox}
          alignmentBaseline="middle"
          textAnchor="middle"
          overflow="auto"
        >
          <g>
            {lineElements}
            {nodeElements}
          </g>
        </svg>
      </div>
    );

  const setScale = (newScale: number) => {
    if (newScale > maxScale) setGraphScale(3);
    else if (newScale < minScale) setGraphScale(1);
    else setGraphScale(newScale);

    setTranslate(graphTranslateX, graphTranslateY);
  };

  const onWheel = (event: any) => {
    setScale(event.deltaY * -0.01 + graphScale);
  };

  const onMouseDown = () => {
    setIsMouseDown(true);
  };

  const onMouseUp = () => {
    setIsMouseDown(false);
  };

  const setTranslate = (newGraphTranslateX: number, newGraphTranslateY: number) => {
    const margeX = 0;
    const margeY = 0;

    const w = width / 2 - margeX;
    if (newGraphTranslateX > w - margeX) {
      setGraphTranslateX(w - margeX);
    } else if (newGraphTranslateX < -w) {
      setGraphTranslateX(-w);
    } else {
      setGraphTranslateX(newGraphTranslateX);
    }

    const h = height / 2;
    if (newGraphTranslateY > h - margeY) {
      setGraphTranslateY(h - margeY);
    } else if (newGraphTranslateY < -h + margeY) {
      setGraphTranslateY(-h + margeY);
    } else {
      setGraphTranslateY(newGraphTranslateY);
    }
  };

  const onMouseMove = (event: any) => {
    if (isMouseDown) {
      const factor = layout.width > layout.height ? layout.width : layout.height;
      const newGraphTranslateX = graphTranslateX + ((event.movementX / 500) * factor) / graphScale;
      const newGraphTranslateY = graphTranslateY + ((event.movementY / 500) * factor) / graphScale;

      setTranslate(newGraphTranslateX, newGraphTranslateY);
    }
  };

  const reinitZoom = () => {
    setGraphTranslateX(0);
    setGraphTranslateY(0);
    setGraphScale(1);
  };

  const zoomButtonStep = 0.5;
  const onZoomInButton = () => setScale(graphScale + zoomButtonStep);
  const onZoomOutButton = () => setScale(graphScale - zoomButtonStep);
  const onRerenderGraph = () => {
    dispatch(setShouldRerender());
  };
  return (
    <div
      ref={containerRef}
      id="graph-div"
      className={classNames(
        "graph",
        {
          allValid: allNodesAreValid,
        },
        "overflow-hidden",
      )}
    >
      {Number(unlinkedSceneNodes?.length) > 0 && (
        <div className="flex self-start mr-28 bg-gray-200 border border-gray-200 rounded-3xl h-[19.666667%] mb-6 pb-2 w-full pl-2">
          <span className="flex items-center w-40">
            <p>
              {Number(unlinkedSceneNodes?.length) === 0
                ? ""
                : Number(unlinkedSceneNodes?.length) === 1
                ? t("pages.project.edit.unlinkedScene")
                : t("pages.project.edit.unlinkedScenes")}
            </p>
            <Tooltip message={t("pages.project.edit.linkSceneInfoTooltip")} className="mb-6">
              <MdInfo></MdInfo>
            </Tooltip>
            :
          </span>
          <div
            className={"overflow-y-auto overflow-x-auto w-full overscroll-x-auto "}
            style={{ width: "calc(100% - 11rem)" }}
          >
            <UnlinkedScenesWrapper />
          </div>
        </div>
      )}
      <div
        className={`${
          Number(unlinkedSceneNodes?.length) > 0 ? "h-5/6" : "h-full"
        } overflow-hidden bg-gray-200 border border-gray-200 rounded-3xl`}
        onWheel={onWheel}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
      >
        <div className="z-10 m-2 absolute flex w-full justify-center h-[5%] min-h-[2.0rem] cursor-grabbing">
          <button
            className="btn-primary-fill ml-1 min-w-0 rounded-full flex items-center"
            onClick={onRerenderGraph}
          >
            <Icon icon={"RefreshIcon"} className={"h-full w-full stroke-white ml-0.5"} />
          </button>
          <div className="px-2">
            <div className="border-l border-gray-500 h-10 "></div>
          </div>
          <button className="btn-primary-fill pb-0 pt-0" onClick={reinitZoom}>
            {t("pages.project.edit.resetZoom")}
          </button>

          <button
            className="btn-primary-fill ml-1 min-w-0 flex items-center"
            disabled={graphScale <= minScale}
            onClick={onZoomOutButton}
          >
            <Icon icon={"ZoomOutIcon"} className={"h-full w-full stroke-white"} />
          </button>

          <button
            className="btn-primary-fill ml-1 min-w-0 flex items-center"
            disabled={graphScale >= maxScale}
            onClick={onZoomInButton}
          >
            <Icon icon={"ZoomInIcon"} className={"h-full w-full stroke-white"} />
          </button>

          {/* <div
            className="absolute right-[5rem] flex items-center"
            style={{
              cursor: "pointer",
            }}
          >
            <Tooltip
              message={t("pages.project.edit.gotoStudioNoob")}
              forceSingleLine={false}
              classNameChildren="w-64 right-20"
            >
              <Icon
                icon={"LightBulbIcon"}
                className="h-10 w-10 text-yellow-400 transition-transform duration-300 ease-in-out hover:scale-110 hover:text-yellow-500"
                fill="#facc15"
              />
            </Tooltip>
          </div> */}
        </div>
        <div className="z-0 cursor-grabbing">{svgElement}</div>
      </div>
    </div>
  );
};

const detectTypeForAssetNode = ({ modelName }: NodeAsset): AssetType => {
  switch (modelName) {
    case "content-audio":
      return "sound";
    case "content-button-1":
    case "content-button-2":
    case "content-clean":
    case "content-quizz-1":
    case "content-quizz-2":
    case "content-quizz-3":
    case "content-yes-no-question":
    case "content-scoring-general":
    case "content-scoring-scene":
    case "content-text":
    case "content-tuto":
    case "content-zone-sort":
    case "content-quizz-sort":
    case "content-speech-to-check":
    case "content-fire-extinguisher":
    case "content-quizz-image-multiple":
    default:
      return "control";
    case "content-thumbnail":
    case "content-onboarding":
    case "content-photo-catch":
    case "content-pdf-viewer":
      return "image";
    case "content-hotspot-scene-video":
    case "content-hotspot-scene-image":
    case "content-vr-locator":
    case "content-zone-detect":
      return "location";
    case "content-video-2-d":
      return "video";
    case "content-3d-viewer":
      return "model3d";
    case "content-fire":
    case "content-blur":
    case "content-dust":
      return "effect";
  }
};

export default SVGGraph;
